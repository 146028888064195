import { FC } from 'react';
import * as React from 'react';
import { useQueryParam, StringParam } from 'use-query-params';

import Layout from '../layouts';

const ThankYouPage: FC = () => {
  const [email] = useQueryParam('email', StringParam);

  return (
    <Layout url="thank-you" title="Thank you" disableIndex>
      <div className="flex-1 flex flex-col items-center justify-center text-center">
        <div>
          <h1>Thank you!</h1>
          <p>Your account waiting for you!</p>
          <p>
            We have sent an email to{' '}
            <strong>{email || 'the email you provided'}</strong>. Please follow
            the instructions to activate your account.
          </p>
          <p className="opacity-70">You can close this page now.</p>
        </div>
      </div>
    </Layout>
  );
};

export default ThankYouPage;
